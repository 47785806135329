// import bootcamp from "../img/products/enterprise/custom-training.webp";
// import damaGA from "../img/events/dama-ga.webp";
import books from "../img/events/dg_books.webp";
// import dmbok from "../img/cdmp/dmbok.webp";
// import chatGPT from "../img/blog/chatGPT.webp";

import john8 from "../img/events/john/john8.webp";
import john9 from "../img/events/john/john9.webp";
import john10 from "../img/events/john/john10.webp";
import john11 from "../img/events/john/john11.webp";
import john12 from "../img/events/john/john12.webp";
import john13 from "../img/events/john/john13.webp";
import john14 from "../img/events/john/john14.webp";
import dv from "../img/events/data-valuation-strategies.webp";

const events: eventDetails[] = [
  // {
  //   id: "cdmp-qa-apr-24",
  //   type: "other",
  //   name: "CDMP Q&A Session",
  //   start: new Date("April 16, 2024 13:00:00"),
  //   end: new Date("April 16, 2024 14:00:00"),
  //   link: "https://lu.ma/4lgw59q7",
  //   image: dmbok,
  // },
  // {
  //   id: "april-cdmp-bootcamp",
  //   type: "other",
  //   name: "April CDMP Bootcamp with DAMA GA",
  //   start: new Date("April 26, 2024 9:00:00"),
  //   end: new Date("April 27, 2024 18:00:00"),
  //   location: `<span>State Farm offices<br/>
  //   237 Perimeter Center Pkwy NE<br/>
  //   Sandy Springs, GA 30346</span>`,
  //   link: "",
  //   image: bootcamp,
  // },
  // {
  //   id: "june-data-valuation-presentation",
  //   type: "other",
  //   name: "Data Valuation presentation for DAMA Georgia",
  //   hoverText: "Please sign up on the DAMA GA website for access to the event",
  //   start: new Date("June 20, 2024 19:00:00"),
  //   end: new Date("June 20, 2024 20:00:00"),
  //   location: `<span>State Farm Dunwoody office<br/>
  //   237 Perimeter Center Pkwy NE,<br/>
  //   Sandy Springs, GA 30346<br/>
  //   (and virtual)</span>`,
  //   link: "https://dama-ga.us/event-5650288",
  //   image: damaGA,
  // },
  {
    id: "june-discussion-group-2",
    type: "Discussion Group",
    name: "June Discussion Group (ch.<span>&nbsp;</span>7)",
    start: new Date("June 30, 2024 18:00:00"),
    end: new Date("June 30, 2024 19:00:00"),
    link: "https://lu.ma/ae48ceio",
    chapterName: "Data Security",
    chapterNumber: 7,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400/event-covers/z6/85439117-e607-4eae-bfa3-3ca7cb0f09fc",
  },
  {
    id: "july-discussion-group-0",
    type: "Discussion Group",
    name: "July Discussion Group (ch.<span>&nbsp;</span>8)",
    start: new Date("July 8, 2024 6:00:00"),
    end: new Date("July 8, 2024 7:00:00"),
    link: "https://lu.ma/571vy1rk",
    chapterName: "Data Integration & Interoperability",
    chapterNumber: 8,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,quality=75,width=400,height=400/event-covers/sz/464ccf0b-0237-4b40-971e-11eed96d7723",
  },
  {
    id: "july-discussion-group-1",
    type: "Discussion Group",
    name: "July Discussion Group (ch.<span>&nbsp;</span>8)",
    start: new Date("July 15, 2024 13:00:00"),
    end: new Date("July 15, 2024 14:00:00"),
    link: "https://lu.ma/6u27n299",
    chapterName: "Data Integration & Interoperability",
    chapterNumber: 8,
    image: john8,
  },
  {
    id: "july-data-governance-presentation",
    type: "other",
    name: "Comparative Methodologies in Data Governance",
    start: new Date("July 18, 2024 13:00:00"),
    end: new Date("July 18, 2024 14:00:00"),
    location: "virtual",
    hoverText: "Please sign up on Luma to receive the Zoom link",
    link: "https://lu.ma/0s7mqj1w",
    image: books,
  },
  // {
  //   id: "july-cdmp-bootcamp",
  //   type: "other",
  //   name: "July CDMP Bootcamp",
  //   start: new Date("July 19, 2024 9:00:00"),
  //   end: new Date("July 20, 2024 16:00:00"),
  //   location: "Zoom",
  //   link: "https://www.datastrategypros.com/products/cdmp-bootcamp",
  //   image: bootcamp,
  // },
  {
    id: "july-discussion-group-2",
    type: "Discussion Group",
    name: "July Discussion Group (ch.<span>&nbsp;</span>8)",
    start: new Date("July 31, 2024 18:00:00"),
    end: new Date("July 31, 2024 19:00:00"),
    link: "https://lu.ma/4qsm8tns",
    chapterName: "Data Integration & Interoperability",
    chapterNumber: 8,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/7n/ff8c2950-7b89-47e6-80a2-4c2f4d0da6c3",
  },
  {
    id: "aug-discussion-group-0",
    type: "Discussion Group",
    name: "August Discussion Group (ch.<span>&nbsp;</span>9)",
    start: new Date("Aug 2, 2024 6:00:00"),
    end: new Date("Aug 2, 2024 7:00:00"),
    link: "https://lu.ma/401az88j",
    chapterName: "Document & Content Management",
    chapterNumber: 9,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,quality=75,width=400,height=400/event-covers/di/9b860647-26f3-4abb-bb36-6be04cee7112",
  },
  {
    id: "august-discussion-group-1",
    type: "Discussion Group",
    name: "August Discussion Group (ch.<span>&nbsp;</span>9)",
    start: new Date("August 15, 2024 13:00:00"),
    end: new Date("August 15, 2024 14:00:00"),
    link: "https://lu.ma/adl9fekj",
    chapterName: "Document & Content Management",
    chapterNumber: 9,
    image: john9,
  },
  {
    id: "august-data-valuation-presentation",
    type: "other",
    name: "Strategies for a Data Valuation Assessment",
    start: new Date("August 16, 2024 13:00:00"),
    end: new Date("August 16, 2024 14:00:00"),
    link: "https://lu.ma/vvrd1l44",
    image: dv,
  },
  {
    id: "august-discussion-group-2",
    type: "Discussion Group",
    name: "August Discussion Group (ch.<span>&nbsp;</span>9)",
    start: new Date("August 31, 2024 18:00:00"),
    end: new Date("August 31, 2024 19:00:00"),
    link: "https://lu.ma/twxyg04w",
    chapterName: "Document & Content Management",
    chapterNumber: 9,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/52/4f561fd6-7ec4-40c5-a54d-45758eef1912",
  },
  {
    id: "september-discussion-group-0",
    type: "Discussion Group",
    name: "September Discussion Group (ch.<span>&nbsp;</span>10)",
    start: new Date("September 6, 2024 6:00:00"),
    end: new Date("September 6, 2024 7:00:00"),
    link: "https://lu.ma/pz7uq03s",
    chapterName: "Master & Reference Data Management",
    chapterNumber: 10,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=400,height=400/event-covers/6j/8236b08d-8525-41be-af43-2264c1a3bb0f",
  },
  {
    id: "september-discussion-group-1",
    type: "Discussion Group",
    name: "September Discussion Group (ch.<span>&nbsp;</span>10)",
    start: new Date("September 15, 2024 13:00:00"),
    end: new Date("September 15, 2024 14:00:00"),
    link: "https://lu.ma/x0tkphcp",
    chapterName: "Master & Reference Data Management",
    chapterNumber: 10,
    image: john10,
  },
  {
    id: "september-discussion-group-2",
    type: "Discussion Group",
    name: "September Discussion Group (ch.<span>&nbsp;</span>10)",
    start: new Date("September 30, 2024 18:00:00"),
    end: new Date("September 30, 2024 19:00:00"),
    link: "https://lu.ma/10h18lcm",
    chapterName: "Master & Reference Data Management",
    chapterNumber: 10,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/nj/4faef4da-1113-4471-9897-0213189b74d7",
  },
  {
    id: "october-discussion-group-0",
    type: "Discussion Group",
    name: "October Discussion Group (ch.<span>&nbsp;</span>11)",
    start: new Date("October 4, 2024 6:00:00"),
    end: new Date("October 4, 2024 7:00:00"),
    link: "https://lu.ma/htw2m9qx",
    chapterName: "Data Warehousing & Business Intelligence",
    chapterNumber: 11,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/o3/ba788ba5-4c90-4027-9b7d-43d37b87019d",
  },

  {
    id: "october-discussion-group-1",
    type: "Discussion Group",
    name: "October Discussion Group (ch.<span>&nbsp;</span>11)",
    start: new Date("October 15, 2024 13:00:00"),
    end: new Date("October 15, 2024 14:00:00"),
    link: "https://lu.ma/424yvpqz",
    chapterName: "Data Warehousing & Business Intelligence",
    chapterNumber: 11,
    image: john11,
  },
  {
    id: "october-discussion-group-2",
    type: "Discussion Group",
    name: "October Discussion Group (ch.<span>&nbsp;</span>11)",
    start: new Date("October 30, 2024 18:00:00"),
    end: new Date("October 30, 2024 19:00:00"),
    link: "https://lu.ma/k6dqmdzu",
    chapterName: "Data Warehousing & Business Intelligence",
    chapterNumber: 11,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/9t/ca4f2687-97dc-4532-989a-3265a8bc1a16",
  },
  {
    id: "november-discussion-group-0",
    type: "Discussion Group",
    name: "November Discussion Group (ch.<span>&nbsp;</span>12)",
    start: new Date("November 4, 2024 6:00:00"),
    end: new Date("November 4, 2024 7:00:00"),
    link: "https://lu.ma/ty60h2is",
    chapterName: "Metadata Management",
    chapterNumber: 12,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=400,height=400/event-covers/d1/8613052b-1625-4feb-a22c-ad3610b2dc51",
  },

  {
    id: "november-discussion-group-1",
    type: "Discussion Group",
    name: "November Discussion Group (ch.<span>&nbsp;</span>12)",
    start: new Date("November 15, 2024 13:00:00"),
    end: new Date("November 15, 2024 14:00:00"),
    link: "https://lu.ma/vvznfupv",
    chapterName: "Metadata Management",
    chapterNumber: 12,
    image: john12,
  },
  {
    id: "november-discussion-group-2",
    type: "Discussion Group",
    name: "November Discussion Group (ch.<span>&nbsp;</span>12)",
    start: new Date("November 30, 2024 18:00:00"),
    end: new Date("November 30, 2024 19:00:00"),
    link: "https://lu.ma/slrlgumn",
    chapterName: "Metadata Management",
    chapterNumber: 12,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/zg/e9ed911a-4d65-4d86-888b-66843fa3f4f6",
  },
  {
    id: "december-discussion-group-0a",
    type: "Discussion Group",
    name: "December Discussion Group (ch.<span>&nbsp;</span>13)",
    start: new Date("December 6, 2024 6:00:00"),
    end: new Date("December 6, 2024 7:00:00"),
    link: "https://lu.ma/ucf13n8g",
    chapterName: "Data Quality",
    chapterNumber: 13,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/hy/7cad79b7-863c-466d-ad5b-7d6a47e09c06",
  },
  {
    id: "december-discussion-group-0b",
    type: "Discussion Group",
    name: "December Discussion Group (ch.<span>&nbsp;</span>14)",
    start: new Date("December 13, 2024 6:00:00"),
    end: new Date("December 13, 2024 7:00:00"),
    link: "https://lu.ma/ztma2d1v",
    chapterName: "Big Data",
    chapterNumber: 14,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=400,height=400/event-covers/ml/dac448fe-7cb9-493e-b90c-57800862f4f7",
  },

  {
    id: "december-discussion-group-1a",
    type: "Discussion Group",
    name: "December Discussion Group (ch.<span>&nbsp;</span>13)",
    start: new Date("December 15, 2024 13:00:00"),
    end: new Date("December 15, 2024 14:00:00"),
    link: "https://lu.ma/szl38no8",
    chapterName: "Data Quality",
    chapterNumber: 13,
    image: john13,
  },
  {
    id: "december-discussion-group-1b",
    type: "Discussion Group",
    name: "December Discussion Group (ch.<span>&nbsp;</span>14)",
    start: new Date("December 15, 2024 18:00:00"),
    end: new Date("December 15, 2024 19:00:00"),
    link: "https://lu.ma/p9wxsjk6",
    chapterName: "Big Data",
    chapterNumber: 14,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/gf/ff9df08a-1a08-4f10-a0ef-607f9da7940d",
  },
  {
    id: "december-discussion-group-2a",
    type: "Discussion Group",
    name: "December Discussion Group (ch.<span>&nbsp;</span>14)",
    start: new Date("December 30, 2024 13:00:00"),
    end: new Date("December 30, 2024 14:00:00"),
    link: "https://lu.ma/f5zs2l6q",
    chapterName: "Big Data",
    chapterNumber: 14,
    image: john14,
  },
  {
    id: "december-discussion-group-2b",
    type: "Discussion Group",
    name: "December Discussion Group (ch.<span>&nbsp;</span>13)",
    start: new Date("December 30, 2024 18:00:00"),
    end: new Date("December 30, 2024 19:00:00"),
    link: "https://lu.ma/jsktxfle",
    chapterName: "Data Quality",
    chapterNumber: 13,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-covers/yh/120df1ad-1c17-441a-854e-136398a95303",
  },
];

export default events;
