type Newsletter = {
  date: number;
  title: string;
  link: string;
};

const newsletters: Newsletter[] = [
  {
    date: 45550,
    title: "How to write a Data Governance Charter ✍️",
    link: "https://sh1.sendinblue.com/15dvsckdea9xpne.html",
  },
  {
    date: 45531,
    title: "Starting a Data Management Strategy with MDM",
    link: "https://sh1.sendinblue.com/15ds1i1pea9xpfe.html",
  },
  {
    date: 45517,
    title: "Running a Data Governance Council like a Pro 🙋",
    link: "https://sh1.sendinblue.com/15drkn8dea9xpje.html?t=1723637628611",
  },
  {
    date: 45503,
    title: "AI's Impact on Document & Content Governance",
    link: "https://sh1.sendinblue.com/15doanj1ea9xpfe.html",
  },
  {
    date: 45487,
    title: "Quantify the value of your data 💸",
    link: "https://sh1.sendinblue.com/15dqmxlpea9xpje.html",
  },
  {
    date: 45468,
    title: "Latest & Integratest",
    link: "https://sh1.sendinblue.com/15dm2l5pea9xpfe.html",
  },
  {
    date: 45454,
    title: "Don't leave your organization vulnerable 🔐",
    link: "https://sh1.sendinblue.com/15dmb0kdea9xpje.html",
  },
  {
    date: 45440,
    title: "Ransomware nightmares",
    link: "https://sh1.sendinblue.com/15dks8f1ea9xpfe.html",
  },
  {
    date: 45426,
    title: "What's shaping the future of cloud storage? 📦",
    link: "https://sh1.sendinblue.com/15djuisdea9xpfe.html",
  },
  {
    date: 45410,
    title: "Are you hoarding data?",
    link: "https://sh1.sendinblue.com/15df1qxpea9xpfe.html",
  },
  {
    date: 45396,
    title: "Learn today's best practices for Data Modeling 🎨",
    link: "https://sh1.sendinblue.com/15dexj8dea9xpfe.html",
  },
  {
    date: 45378,
    title: "Foster a data-driven culture",
    link: "https://sh1.sendinblue.com/15dbf44dea9xpfe.html",
  },
  {
    date: 45364,
    title: "Leading insights through data-driven decision-making 📊",
    link: "https://sh1.sendinblue.com/15d9nwkdea9xpfe.html",
  },
  {
    date: 45349,
    title: "Enhance your productivity with AI copilots",
    link: "https://sh1.sendinblue.com/15d6qjz1ea9xpfe.html",
  },
  {
    date: 45336,
    title: "First steps towards data-driven insights 💡",
    link: "https://sh1.sendinblue.com/15d5x21pea9xpfe.html",
  },
  {
    date: 45321,
    title: "Intelligent Data Governance by Design",
    link: "https://sh1.sendinblue.com/3k1i6cdea9xpfe.html",
  },
  {
    date: 45305,
    title: "The path to great success in Data Governance ✅",
    link: "https://sh1.sendinblue.com/3jx21lpea9xpfe.html",
  },
  {
    date: 45293,
    title: "Market projections for Data Management jobs",
    link: "https://sh1.sendinblue.com/3jvau1pea9xpfe.html",
  },
  {
    date: 45272,
    title: "How to think about data differently 💸",
    link: "https://sh1.sendinblue.com/3jknd0dea9xpfe.html",
  },
  {
    date: 45258,
    title: "The biggest news in AI",
    link: "https://sh1.sendinblue.com/3jjlfodea9xpfe.html",
  },
  {
    date: 45244,
    title: "Don't make this metadata mistake ⚠️",
    link: "https://sh1.sendinblue.com/3jkexlpea9xpfe.html",
  },
  {
    date: 45228,
    title: "Could this technology disrupt a $1.86B market?",
    link: "https://sh1.sendinblue.com/3jinq1pea9xpfe.html",
  },
  {
    date: 45211,
    title: "See how the EU is improving data sharing 👀",
    link: "https://sh1.sendinblue.com/3jhq0f1ea9xpfe.html",
  },
  {
    date: 45195,
    title: "Data Strategy News",
    link: "https://sh1.sendinblue.com/3j13mhpea9xpfe.html",
  },
  {
    date: 45181,
    title: "Level up your Data Management skills with YouTube ↗️",
    link: "https://sh1.sendinblue.com/3izp21pea9xpfe.html",
  },
  {
    date: 45167,
    title: "Data Strategy News",
    link: "https://sh1.sendinblue.com/3j0v731ea9xpfe.html",
  },
  {
    date: 45152,
    title: "Accelerate your Career with Data Management Specialist Exams 🏎️",
    link: "https://sh1.sendinblue.com/3hj0rdpea9xpfe.html",
  },
  {
    date: 45137,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/datastrategypros/ai-news",
  },
  {
    date: 45120,
    title: `Empower your Summer ☀️`,
    link: "http://eepurl.com/ivmHoE",
  },
  {
    date: 45104,
    title: `Data Strategy News`,
    link: "http://eepurl.com/ipbch6",
  },
  {
    date: 45090,
    title: `Supercharge Your CDMP Exam Prep ⚡️`,
    link: "http://eepurl.com/isHKwA",
  },
  {
    date: 45076,
    title: `Data Strategy News`,
    link: "http://eepurl.com/ioYDME",
  },
  {
    date: 45041,
    title: `Data Strategy News`,
    link: "http://eepurl.com/inZLIw",
  },
  {
    date: 45029,
    title: `Tell Us How You Really Feel 👂`,
    link: "http://eepurl.com/ioTR4-/",
  },
  {
    date: 45013,
    title: `Data Strategy News`,
    link: "http://eepurl.com/ilF5_X",
  },
  {
    date: 44983,
    title: `Data Strategy News`,
    link: "http://eepurl.com/iiR8bj",
  },
  {
    date: 44955,
    title: `Data Strategy News`,
    link: "http://eepurl.com/ijuNnz",
  },
  {
    date: 44936,
    title: `Become a Workplace Hero 🦸`,
    link: "http://eepurl.com/ihQIHT",
  },
  {
    date: 44922,
    title: `Data Strategy News`,
    link: "http://eepurl.com/ie8C7f",
  },
  {
    date: 44894,
    title: `Data Strategy News`,
    link: "http://eepurl.com/h-N5cn",
  },
  {
    date: 44864,
    title: `Data Strategy News`,
    link: "http://eepurl.com/h092AD",
  },
  {
    date: 44845,
    title: `We're playing with fire 🔥`,
    link: "http://eepurl.com/iaVKN5",
  },
  {
    date: 44831,
    title: `Data Strategy News`,
    link: "http://eepurl.com/h_5B0r",
  },
  {
    date: 44803,
    title: `Data Strategy News`,
    link: "http://eepurl.com/h0YiX9",
  },
  {
    date: 44773,
    title: `Data Strategy News`,
    link: "http://eepurl.com/h0YgNr",
  },
  {
    date: 44740,
    title: `Data Strategy News`,
    link: "http://eepurl.com/h0YfiX",
  },
  {
    date: 44710,
    title: `Data Strategy News`,
    link: "http://eepurl.com/hZPdrP",
  },
  {
    date: 44698,
    title: `See you tomorrow 👋`,
    link: "https://mailchi.mp/844ddccfe8a4/productivity-tips",
  },
  {
    date: 44682,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/aedf1a965410/crypto-and-coffee-lovers-edition",
  },
  {
    date: 44649,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/9766fc686874/data-trap",
  },
  {
    date: 44647,
    title: `Last Day 🏁`,
    link: "https://mailchi.mp/ecd5ba961fd4/end-of-march",
  },
  {
    date: 44633,
    title: `3 Accessible Life Hacks`,
    link: "https://mailchi.mp/0fde9301735f/accessible-life-hacks",
  },
  {
    date: 44619,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/64bce78db4f8/7-days-toward-acing-the-cdmp-7611337",
  },
  {
    date: 44612,
    title: `Why Coaching is More Important than You Think 💡`,
    link: "https://mailchi.mp/584bc99de519/importance-of-coaching",
  },
  {
    date: 44591,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/3b04d0dd19f9/7-days-toward-acing-the-cdmp-7277842",
  },
  {
    date: 44567,
    title: `CDMP Exam Questions are here!!`,
    link: "https://mailchi.mp/95aa4ffe2f6a/data-strategy-professionals-7415986",
  },
  {
    date: 44563,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/b2b4c939188a/data-strategy-professionals-7277838",
  },
  {
    date: 44549,
    title: `Underrated Data Science 🐍`,
    link: "https://mailchi.mp/8886d7ac57a8/7-days-toward-acing-the-cdmp-7276214",
  },
  {
    date: 44542,
    title: `Sorry in advance...`,
    link: "https://mailchi.mp/29588a7d0ded/7-days-toward-acing-the-cdmp-7272870",
  },
  {
    date: 44530,
    title: `Responsible Data Science Giveaway Winner 🏆`,
    link: "https://mailchi.mp/bb665d26fd95/7-days-toward-acing-the-cdmp-7271842",
  },
  {
    date: 44528,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/f5892ca3f477/7-days-toward-acing-the-cdmp-7271798",
  },
  {
    date: 44514,
    title: `November Giveaway ⚖️`,
    link: "https://mailchi.mp/7877c5329e9a/7-days-toward-acing-the-cdmp-7269142",
  },
  {
    date: 44500,
    title: `Data Strategy News`,
    link: "https://mailchi.mp/5d0afd96123a/7-days-toward-acing-the-cdmp-7264570",
  },
  {
    date: 44430,
    title: `Enjoying the CDMP Study Group? 🧑‍🎓`,
    link: "https://mailchi.mp/994651e095a3/7-days-toward-acing-the-cdmp-7253070",
  },
  {
    date: 44411,
    title: `Certified Data Management Professional explained video 🍿`,
    link: "https://mailchi.mp/99e566cff75d/7-days-toward-acing-the-cdmp-7250253",
  },
];

export default newsletters;
